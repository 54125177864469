import React from "react"
import styled from "@emotion/styled"
import CutleryIcon from "./CutleryIcon"
import InstagramIcon from "./InstagramIcon"

const AboutContent = ({instagram, lead, content}) => {
  return(
    <ContentWrapper>
      <InstagramLink href={instagram} target="_blank">
        <InstagramIcon />
      </InstagramLink>
      <ContentInner>
        <Lead>{lead}</Lead>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <CutleryIconWrapper>
          <CutleryIcon />
        </CutleryIconWrapper>
      </ContentInner>
    </ContentWrapper>
  )
}

export default AboutContent

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 90px;
`

const ContentInner = styled.div`
  width: 100%;
  padding: 0 24px;
  
  @media (min-width: 991px) {
    flex: 0 0 50%;
    padding: 0 12px;
  }
`

const Lead = styled.p`
  font-size: 1.875rem;
  line-height: 2.5rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 21px;
`

const CutleryIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;

  @media (min-width: 991px) {
    justify-content: flex-start;
  }
`

const InstagramLink = styled.a`
  display: none;

  @media (min-width: 991px) {
    display: block;
  }
`