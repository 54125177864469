import React from "react"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Article } from "../components/commons/styled-components"
import Container from "../components/commons/container"
import FeaturedImage from "../components/about/FeaturedImg"
import AboutContent from "../components/about/AboutContent"

const Sobre = ({ data }) => {
  const content = data.wpPage.about

  return (
    <Layout seo={data.wpPage.seo}>
      <Article
        css={css`
          background-color: #d99f4f;
          margin-bottom: -50px;
        `}
      >
        <Container>
          <FeaturedImage image={content.imagemDestaque} />
          <AboutContent
            instagram={content.linkInstagram}
            lead={content.textoDestaque}
            content={content.texto}
          />
        </Container>
      </Article>
    </Layout>
  )
}

export default Sobre

export const query = graphql`
  query AboutQuery {
    wpPage(slug: { eq: "sobre" }) {
      seo {
        canonical
        title
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
      }
      about {
        imagemDestaque {
          altText
          srcSet
        }
        texto
        textoDestaque
        linkInstagram
      }
    }
  }
`
