import React from "react"

const InstagramIcon = () => {
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.5"
      height="19.5"
      viewBox="0 0 19.5 19.5"
    >
      <g transform="translate(0.481 0.638)">
        <rect
          width="18"
          height="18"
          rx="5"
          transform="translate(0.269 0.112)"
          fill="none"
          stroke="#1d1f1d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M15.293,11.067A3.645,3.645,0,1,1,12.222,8,3.645,3.645,0,0,1,15.293,11.067Z"
          transform="translate(-2.537 -2.529)"
          fill="none"
          stroke="#1d1f1d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="0.009"
          transform="translate(14.123 4.1)"
          fill="none"
          stroke="#1d1f1d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}

export default InstagramIcon
