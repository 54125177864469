import React from "react"
import styled from "@emotion/styled"

const FeaturedImage = ({image}) => {
  return(
    <ImageContainer>
      <StyledImage alt={image.altText} srcSet={image.srcSet} />
    </ImageContainer>
  )
}

export default FeaturedImage

const StyledImage = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 13px;
`

const ImageContainer = styled.div`
  width: 100%;
  padding: 50px 24px 40px;
  
  @media (min-width: 991px) {
    width: 70%;
    padding: 50px 0 40px;
  }
`